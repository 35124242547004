// IMPORTS
import React from "react";
import { FaShoppingCart } from "react-icons/fa";
import { VscChromeClose } from "react-icons/vsc";
// COMPONENT
const Header = ({ appRef }) => {
  const { cart, setCart, categories, filters, setFilters } = appRef;
  // RENDER
  return (
    <div className="headerContainer">
      {/* TITLE */}
      <h1 className="headerTitle">Calgary Cakery</h1>
      {/* BUTTONS */}
      <div className="headerButtonsContainer">
        {categories.map((category, index) => {
          return (
            <button
              key={index}
              className={
                category === filters.category
                  ? "headerButtonSelected"
                  : "headerButton"
              }
              onClick={() => {
                setFilters({
                  ...filters,
                  category: filters.category === category ? "All" : category,
                });
              }}
            >
              {category}
            </button>
          );
        })}
      </div>
      {/* CART */}
      <div className="headerCartContainer">
        <button
          className="headerCartButton"
          onClick={() => {
            setCart({
              ...cart,
              show: !cart.show,
            });
          }}
        >
          {cart.items.length > 0 && (
            <span className="headerCartButtonNumber">{cart.items.length}</span>
          )}
          {cart.show ? <VscChromeClose /> : <FaShoppingCart />}
        </button>
      </div>
    </div>
  );
};
// EXPORT
export default Header;
