// IMPORTS
import React, { useState } from "react";
import { MdSort } from "react-icons/md";
// COMPONENT
const Browser = ({ appRef }) => {
  // STATE
  const { tags, filters, setFilters } = appRef;
  const [isTyping, setIsTyping] = useState(false);
  const sortingOptions = ["A - Z", "Z - A", "Price ↓", "Price ↑"];
  // RENDER
  return (
    <div className="browserContainer">
      {/* SEARCH */}
      <div className="searchContainer">
        {filters.search === "" && (
          <label
            htmlFor="search"
            className={isTyping ? "searchLabel" : "searchLabelCenter"}
          >
            search
          </label>
        )}
        <textarea
          id="search"
          className="searchTextArea"
          type="text"
          value={filters.search}
          onChange={(event) => {
            setFilters({
              ...filters,
              search: event.target.value,
            });
          }}
          onFocus={() => setIsTyping(true)}
          onBlur={() => setIsTyping(false)}
        />
      </div>
      {/* TAGS */}
      <div className="tagsContainer">
        <div className="sortByContainer">
          <label className="sortByLabel" htmlFor="sortBy">
            <MdSort />
          </label>
          <select
            name="sortBy"
            className="sortBySelect"
            onChange={async (event) => {
              setFilters({
                ...filters,
                sortBy: event.target.value,
              });
            }}
          >
            {sortingOptions.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
        <div className="tags">
          {tags.map((tag, index) => {
            return (
              <button
                key={index}
                className={tag === filters.tag ? "tagSelected" : "tag"}
                onClick={() => {
                  setFilters({
                    ...filters,
                    tag,
                  });
                }}
              >
                {tag}
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
};
// EXPORT
export default Browser;
