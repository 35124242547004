// IMPORTS
import "./styles/App.scss";
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { FaTimes } from "react-icons/fa";
import { fetchWordpress, sortProducts } from "./helpers/methods";
import Header from "./components/Header";
import Cart from "./components/Cart";
import Browser from "./components/Browser";
import Product from "./components/Product";
// COMPONENT
const App = () => {
  // STATE
  const [cart, setCart] = useState({
    show: false,
    items: localStorage.getItem("cart")
      ? JSON.parse(localStorage.getItem("cart"))
      : [],
    isCheckingOut: false,
  });
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [tags, setTags] = useState([]);
  const [filters, setFilters] = useState({
    search: "",
    category: "All",
    tag: "All",
    sortBy: "A - Z",
  });
  const [toast, setToast] = useState("");
  // LIFE CYCLE
  useEffect(() => {
    fetchWordpress(setProducts, setCategories, setTags);
    let pathname = window.location.pathname;
    if (pathname === "/success") {
      localStorage.removeItem("cart");
      setCart((currentCart) => {
        return { ...currentCart, items: [] };
      });
      setToast("Your purchase was successful");
      window.history.replaceState({}, "Calgary Cakery", "/");
    } else if (pathname === "/failed") {
      setToast("Your purchase has failed");
      window.history.replaceState({}, "Calgary Cakery", "/");
    }
  }, []);
  // APP REF
  const appRef = {
    cart,
    setCart,
    products,
    categories,
    tags,
    filters,
    setFilters,
    toast,
    setToast,
  };
  // RENDER
  return (
    <div className="appContainer">
      {toast !== "" && (
        <button className="appToast" onClick={() => setToast("")}>
          <FaTimes style={{ marginBottom: "0.2rem" }} /> {toast}
        </button>
      )}
      <Header appRef={appRef} />
      <Cart appRef={appRef} />
      <Browser appRef={appRef} />
      <div className="productsContainer">
        {sortProducts(products, filters.sortBy).map((product, index) => {
          const props = {
            product,
            cart,
            setCart,
            filters,
          };
          return <Product key={index} props={props} />;
        })}
      </div>
    </div>
  );
};
// INITIALIZE
ReactDOM.render(
  <Elements stripe={loadStripe(process.env.REACT_APP_STRIPE_LIVE_KEY)}>
    <App />
  </Elements>,
  document.getElementById("root")
);
