// IMPORTS
import React, { useState } from "react";
import { useStripe } from "@stripe/react-stripe-js";
import { fetchAPI } from "../helpers/methods";
// COMPONENT
const Cart = ({ appRef }) => {
  // STATE
  const { cart, setCart } = appRef;
  const { show, items, isCheckingOut } = cart;
  const stripe = useStripe();
  const [inputs, setInputs] = useState({
    fields: ["address", "city", "postal Code", "province"],
    address: "",
    city: "",
    province: "",
    postalCode: "",
  });
  const cartIsEmpty = items.length === 0;
  // PAYMENT OPTIONS
  const paymentOptions = [
    {
      title: "Pay with Card",
      method: async () => {
        const { id: sessionId } = await fetchAPI("stripe-checkout", {
          items: items.map((item) => {
            const { title, image, flavour, filling, price } = item;
            return {
              name: title,
              description: filling ? `${flavour} / ${filling}` : flavour,
              images: [image],
              amount: parseInt(price, 10) * 100,
              currency: "cad",
              quantity: 1,
            };
          }),
        });
        stripe.redirectToCheckout({ sessionId });
      },
    },
    {
      title: "Pay with Crypto",
      method: async () => {
        let itemObject = {
          name: [],
          description: [],
          logo_url: "",
          local_price: {
            amount: 0,
            currency: "CAD",
          },
          pricing_type: "fixed_price",
        };
        items.map((item, index) => {
          const { title, flavour, filling, price, category } = item;
          itemObject.name.push(category);
          if (filling) {
            itemObject.description.push(
              `${index + 1}: ${flavour} ${title} ${category.slice(0, -1)} 
              with ${filling}. `
            );
          } else {
            itemObject.description.push(
              `${index + 1}: ${flavour} ${title} ${category.slice(0, -1)}. `
            );
          }
          itemObject.local_price.amount += price;
          return null;
        });
        const response = await fetchAPI("coinbase-checkout", {
          item: {
            ...itemObject,
            name: [...new Set(itemObject.name)].join(" & "),
            description: itemObject.description.join(" "),
          },
        });
        window.location.replace(response.hosted_url);
      },
    },
  ];
  // RENDER
  return (
    <div
      className="cartContainer"
      style={{
        right: show ? "0rem" : "-50rem",
      }}
    >
      {/* CHECKOUT */}
      <div className="cartCheckoutContainer">
        <h2 className="cartCheckoutTitle">
          Total: ${items.reduce((total, item) => (total += item.price), 0)}
        </h2>
        <button
          className={
            cartIsEmpty ? "cartCheckoutButtonDisabled" : "cartCheckoutButton"
          }
          onClick={() => {
            setCart({
              ...cart,
              isCheckingOut: !isCheckingOut,
            });
          }}
          disabled={cartIsEmpty}
        >
          {`${isCheckingOut ? "Cancel" : "Start"} Checkout`}
        </button>
      </div>
      {/* CHECKOUT FORM */}
      <div
        className="cartCheckoutForm"
        style={{
          top: isCheckingOut ? "8rem" : "-15rem",
        }}
      >
        {inputs.fields.map((field, index) => {
          const fieldName = field.split(" ").join("");
          return (
            <div key={index} className="cartCheckoutField">
              <label className="cartCheckoutLabel" htmlFor={field}>
                {field}
              </label>
              <input
                name={field}
                className="cartCheckoutInput"
                type="text"
                value={inputs[fieldName]}
                onChange={(event) => {
                  setInputs({
                    ...inputs,
                    [fieldName]: event.target.value,
                  });
                }}
              />
            </div>
          );
        })}
        {paymentOptions.map((option, index) => {
          const { title, method } = option;
          const formIsValid =
            inputs.address !== "" &&
            inputs.city !== "" &&
            inputs.postalCode !== "" &&
            inputs.province !== "";
          return (
            <button
              key={index}
              className={
                formIsValid
                  ? "cartCheckoutButton"
                  : "cartCheckoutButtonDisabled"
              }
              onClick={method}
              disabled={!formIsValid}
            >
              {title}
            </button>
          );
        })}
      </div>
      {/* ITEMS */}
      <ul
        className="cartItemsContainer"
        style={{
          height: isCheckingOut ? "calc(100% - 33rem)" : "calc(100% - 8rem)",
        }}
      >
        {items.map((item, index1) => {
          const { title, image, filling } = item;
          return (
            <li key={index1} className="cartItem">
              <div className="cartItemDetails">
                <h3 className="cartItemTitle">{title}</h3>
                {["flavour", ...(filling ? ["filling"] : []), "price"].map(
                  (detail, index2) => {
                    const isPriceDetail = detail === "price";
                    return (
                      <div key={index2} className="cartItemDetail">
                        <p className="cartItemText">{detail}</p>
                        <div
                          className={`cartItemPreview ${
                            !isPriceDetail && item[detail].split(" ").join("")
                          }`}
                          style={{
                            height: isPriceDetail && "0rem",
                            width: isPriceDetail && "0rem",
                            backgroundSize: !isPriceDetail && "50px 50px",
                          }}
                        />
                        <p className="cartItemText">
                          {isPriceDetail && "$"}
                          {item[detail]}
                        </p>
                      </div>
                    );
                  }
                )}
                <button
                  className="cartItemButton"
                  onClick={() => {
                    const updatedItems = items.filter(
                      (currentItem) => currentItem !== item
                    );
                    setCart({
                      ...cart,
                      items: updatedItems,
                    });
                    localStorage.setItem("cart", JSON.stringify(updatedItems));
                  }}
                >
                  Remove
                </button>
              </div>
              <img
                className="cartItemImage"
                alt="Cart Item"
                src={image}
                draggable={false}
              />
            </li>
          );
        })}
        {cartIsEmpty && (
          <p className="cartItemTitleCenter">You have no items in your cart</p>
        )}
      </ul>
    </div>
  );
};
// EXPORT
export default Cart;
