// IMPORTS
import React, { useEffect, useState } from "react";
// COMPONENT
const Product = ({ props }) => {
  // STATE
  const { product, cart, setCart, filters } = props;
  const { title, image, flavours, fillings, price, category, tags } = product;
  const { search, tag } = filters;
  const [selected, setSelected] = useState({
    flavour: null,
    filling: null,
  });
  // LIFE CYCLE
  useEffect(() => {
    setSelected({
      flavour: flavours[0],
      filling: fillings ? fillings[0] : null,
    });
  }, [fillings, flavours]);
  // SHOW PRODUCT
  const showProduct = () => {
    return category === filters.category || filters.category === "All"
      ? search === ""
        ? (tags.includes(tag) || tag === "All") && true
        : (tags.join(" ").toLowerCase().includes(search.toLowerCase()) ||
            title.toLowerCase().includes(search.toLowerCase())) &&
          true
      : false;
  };
  // RENDER
  return (
    showProduct() && (
      <div className="productContainer">
        {/* IMAGE */}
        <div className="productImageContainer">
          <img
            className="productImage"
            alt="Products"
            src={image}
            draggable={false}
          />
        </div>
        {/* DETAILS */}
        <div className="productDetailsContainer">
          <h3 className="productTitle">{title}</h3>
          <p className="productPrice">${price}</p>
          {["flavour", ...(fillings ? ["filling"] : [])].map((type, index1) => {
            const isFlavour = type === "flavour";
            const isFilling = type === "filling";
            const previeImageClassName =
              selected[type] !== null && selected[type].split(" ").join("");
            return (
              <div key={index1} className="productCustomizer">
                <label className="productLabel" htmlFor={type}>
                  {type}
                </label>
                <select
                  name={type}
                  className="productSelect"
                  style={{
                    borderTopLeftRadius: isFlavour && "25px",
                    borderBottomLeftRadius: (isFilling || !fillings) && "25px",
                  }}
                  onChange={(event) => {
                    setSelected({
                      ...selected,
                      [type]: event.target.value,
                    });
                  }}
                >
                  {[...(isFlavour ? flavours : isFilling && fillings)].map(
                    (option, index2) => (
                      <option key={index2} value={option}>
                        {option}
                      </option>
                    )
                  )}
                </select>
                <span className="selectArrow" />
                <div
                  className={`productPreview ${previeImageClassName}`}
                  style={{
                    borderBottomRightRadius:
                      (isFilling || !fillings) && "2.5rem",
                    backgroundSize: "125px 125px",
                  }}
                />
              </div>
            );
          })}
        </div>
        {/* BUTTON */}
        <div className="productButtonContainer">
          <button
            className="productButton"
            onClick={() => {
              const item = {
                title,
                image,
                flavour: selected.flavour,
                filling: selected.filling,
                price,
                category,
              };
              setCart({
                ...cart,
                items: [...cart.items, item],
              });
              localStorage.setItem(
                "cart",
                JSON.stringify([...cart.items, item])
              );
            }}
          >
            Add to Cart
          </button>
        </div>
      </div>
    )
  );
};
// PRODUCT
export default Product;
