// FETCH API
const fetchAPI = async (endpoint, body) => {
  const response = await fetch(
    `${process.env.REACT_APP_EXPRESS_API}/${endpoint}`,
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
    }
  ).catch((error) => {
    console.log(`FETCH ${endpoint} ERROR => `, error);
  });
  return response.json();
};
// FETCH WORDPRESS
const fetchWordpress = (setProducts, setCategories, setTags) => {
  const Query = {
    query: `
      {
        products(first: 50000) {
          nodes {
            title
            details {
              fillings
              flavours
              image {
                sourceUrl
              }
              price
            }
            categories {
              nodes {
                name
              }
            }
            tags {
              nodes {
                name
              }
            }
          }
        }
        categories(first: 50000) {
          nodes {
            name
          }
        }
        tags(first: 50000) {
          nodes {
            name
          }
        }
      }`,
  };
  fetch(process.env.REACT_APP_GRAPHQL_API, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(Query),
  })
    .then((response) => response.json())
    .then((response) => {
      let allProducts = [];
      response.data.products.nodes.map((product) => {
        return allProducts.push({
          title: product.title,
          image: product.details.image.sourceUrl,
          flavours: product.details.flavours,
          fillings: product.details.fillings,
          price: product.details.price,
          category: product.categories.nodes[0].name,
          tags: product.tags.nodes.map((tag) => tag.name),
        });
      });
      let allCategories = [];
      response.data.categories.nodes.map((category) => {
        return allCategories.push(category.name);
      });
      let allTags = [];
      response.data.tags.nodes.map((tag) => {
        return allTags.push(tag.name);
      });
      allTags.unshift("All");
      setProducts(allProducts);
      setCategories(allCategories);
      setTags(allTags);
    })
    .catch((error) => {
      console.log("FETCH WORDPRESS ERROR =>", error);
    });
};
// SORT PRODUCTS
const sortProducts = (products, sortBy) => {
  switch (sortBy) {
    case "A - Z":
      return products.sort((a, b) => {
        if (a.title > b.title) {
          return 1;
        } else {
          return -1;
        }
      });
    case "Z - A":
      return products.sort((a, b) => {
        if (a.title > b.title) {
          return -1;
        } else {
          return 1;
        }
      });
    case "Price ↓":
      return products.sort((a, b) => {
        if (a.price > b.price) {
          return -1;
        } else {
          return 1;
        }
      });
    case "Price ↑":
      return products.sort((a, b) => {
        if (a.price > b.price) {
          return 1;
        } else {
          return -1;
        }
      });
    default:
      break;
  }
};
// EXPORTS
export { fetchAPI, fetchWordpress, sortProducts };
